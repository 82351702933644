// Generated by Framer (28b2919)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Button2 from "https://framerusercontent.com/modules/i2ZVuVfBKA6mq28zeHd0/8dSchdUyKS3o9i4wcWlQ/E8MuRUNcZ.js";
const Button2Fonts = getFonts(Button2);

const cycleOrder = ["ego_meTdr"];

const variantClassNames = {ego_meTdr: "framer-v-h54102"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ego_meTdr", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QSCJV", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-h54102", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ego_meTdr"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><motion.div className={"framer-y4g39u-container"} layoutDependency={layoutDependency} layoutId={"tFfdQ9CK3-container"} transformTemplate={transformTemplate}><Button2 color={"rgb(0, 0, 0)"} fontSize={42} fontSize2={42} height={"100%"} id={"tFfdQ9CK3"} layoutId={"tFfdQ9CK3"} search={"wave"} style={{height: "100%", width: "100%"}} title={"Say Hello"} title2={"We're nice"} title3={"Hello"} title4={"Hello"} title5={"Hello"} variant={"NAJP0jYgz"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QSCJV [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QSCJV .framer-1urp2wt { display: block; }", ".framer-QSCJV .framer-h54102 { height: 101px; overflow: hidden; position: relative; width: 356px; }", ".framer-QSCJV .framer-y4g39u-container { flex: none; height: 100px; left: calc(50.00000000000002% - 100% / 2); position: absolute; top: calc(49.50495049504953% - 100px / 2); width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 101
 * @framerIntrinsicWidth 356
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerqmzFYcnig: React.ComponentType<Props> = withCSS(Component, css, "framer-QSCJV") as typeof Component;
export default FramerqmzFYcnig;

FramerqmzFYcnig.displayName = "light mode";

FramerqmzFYcnig.defaultProps = {height: 101, width: 356};

addFonts(FramerqmzFYcnig, [...Button2Fonts])